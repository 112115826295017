import React from "react";
import Banner from "../../../components/Landers/Banner";
import Brands from "../../../components/Landers/BrandsTemp";
import Footer from "../../../components/Landers/Footer";
import GetStarted from "../../../components/Landers/GetStarted";
import Header from "../../../components/Landers/Header";
import Info from "../../../components/Landers/Info";
import styles from "../../../components/Landers/landers.module.scss";
import SectionTemplate from "../../../components/Landers/SectionTemplate";
import SEO from "../../../components/Landers/SEO";
import Testimonials from "../../../components/Landers/Testimonials";
import ThreeSteps from "../../../components/Landers/ThreeSteps";
import WhatsUnique from "../../../components/Landers/WhatsUnique";
import DataInfo from "../../../components/Data";

const steps = [
  {
    h5: "Initiate Education Verification",
    p:
      "Candidates are requested to submit the background verification form along with the supporting documents to initiate the process.",
  },
  {
    h5: "Run thorough checks",
    p: `The request for confirming the educational details of candidates shall be raised in one of four methods: personal visit to the educational institutions, courier, e-mail, or fax.`,
  },
  {
    h5: "Verify candidate’s education history",
    p:
      "Get detailed information on qualification, name of the institution, duration, year of passing, and more. Review if it is in line with the information provided by the candidate.",
  },
];

const testimonials = [
  {
    name: "Rupali M",
    company: "Integrichain India Pvt. Ltd.",
    p: `The best part about SpringVerify is its user portal. 
    It provides real-time updates making our HR job easier. 
    The entire process is so seamless saving us a lot of time, effort and resources in the process. `,
  },
  {
    name: "Manvi K ",
    company: "Inshorts",
    p: `One thing that really stands-out is their GREAT customer service. 
    The Dedicated Account Manager responds in a very timely manner to any question at the drop of a
    hat and you really can't ask for more than that!`,
  },
  {
    name: "Nandita N",
    company: "Umlaut Pvt. Ltd.",
    p: `SpringVerify is a true all-rounder when it comes to background verifications. 
    Most services I've used in the past have always had compromises. But SpringVerify has been exceptional and impressive - 
    right from the variety of checks it offers, its reliablity to 24x7 customer support. `,
  },
];

export default () => (
  <div className={styles.Education}>
    <SEO
      title="Best Education Verification Service | SpringVerify India"
      desc="Ensure fair and reliable hiring by confirming candidate's education credentials like education history, duration, training and certifications."
      url="https://in.springverify.com/screenings/education-verification/"
      imgurl="https://assets-sv-in-lander.s3.ap-south-1.amazonaws.com/LP/seo/SV-IN+(education+verification).jpeg"
    />
    <div className={styles.topSection}>
      <Header />
      <Banner
        h1={"Reliable Education \nVerifications made \nSuper-Simple"}
        p={
          "Make informed hiring decisions by confirming your \ncandidate's education credentials, degree, professional \nlicense, certification, and more."
        }
        img="https://assets-sv-in.gumlet.io/LP/Education/banner.png"
      />
    </div>
    <Brands data={DataInfo.CRBrandsTemp} />
    <section>
      <SectionTemplate
        img="Education/need.png"
        h2={"Need for \nEducation Verifications"}
        p={`Over 86% of employers have reported that proper screening techniques have exposed candidates who lied on their resumes. Checking genuine qualifications is crucial in making well-informed hiring decisions. Thus education verifications can help you to:`}
        ul={[
          "Hire qualified candidates",
          "Identify false education credentials",
          "Spot any informational discrepancies, red flags, or violations",
          "Save resources and protect your organization against liability claims",
        ]}
      />
    </section>

    <Info
      h2={"Get the entire education history \nof the candidate in one place"}
      path="Education"
      info={[
        "Course enrollment",
        "Duration",
        "Major of degree",
        "GPA scores",
        "Graduation status",
      ]}
    />

    <ThreeSteps
      subHeader="Easy onboarding"
      steps={steps}
      candidate={"https://assets-sv-in.gumlet.io/LP/Education/three.svg"}
      path="Education"
    />
    <WhatsUnique
      path="Education"
      info={[
        "Easy onboarding",
        "Safe and secure",
        "Transparent pricing",
        "Workflow automation",
        "Tailored solutions based on your requirements",
        "Powered by AI and Blockchain",
        "Remote-friendly and contactless verifications",
        "Compliant with \nISO 27001:9000, PCI, and more",
      ]}
    />
    <Testimonials testimonials={testimonials} path="Education" />
    <GetStarted
      img="Education/EduGetStarted.svg"
      h3={"Ready to get started \nwith us?"}
      p="Partner with the most robust background verification service for a safe and secure workplace."
    />
    <Footer />
  </div>
);
